.fullscreen-steps-wizard {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 250px minmax(min-content, 1fr) 90px;
  -ms-grid-rows: 45px ~"calc(100% - 90px)" 45px;
  grid-template-columns: 250px auto 90px;
  grid-template-rows: 45px auto 45px;
  grid-template-areas:
      "header header button-group"
      "nav main main"
      "footer footer footer";
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #FFF;
  overflow: auto;
  z-index: 6000;
  > div.wizard-header {
    -ms-grid-column-span: 2;
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    grid-area: header;
    background: @header-background;
    color: @header-link-color;
    h1 {
      margin: auto 0;
      padding: 10px 15px 6px 14px;
      text-overflow: ellipsis;
      -ms-text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 140%;
    }
    pss-add-favorite > a > pds-icon, 
    pss-add-favorite > a > pds-icon.favorited {
      fill: #ffffff;
    }
  }
  > div.button-group {
    -ms-grid-column-span: 1;
    -ms-grid-column: 3;
    -ms-grid-row: 1;
    grid-area: button-group;
    background: @header-background;
    position: relative;
    display: flex;
    flex-direction: row;
    > a:nth-child(n) {
      padding: 10px;
      margin: 1px 5px 1px 0;
      position: relative;
    }
  }
  & .stepped-container {
    border-right: #ccc solid 1px;
    background: @zebra;
    -ms-grid-column-span: 1;
    -ms-grid-column: 1;
    -ms-grid-row: 2;
    grid-area: nav;
    height: auto;
   & .steps-container ul li {
      margin-top: 7px;
   }
   & .steps-container ul li:after {
        height: 29px;
        top: 31px;
        z-index: 0;
    }
  }
  & .step-content {
    -ms-grid-column-span: 2;
    -ms-grid-column: 2;
    -ms-grid-row: 2;
    grid-area: main;
    padding: 20px;
    overflow-y: auto;
    & .step-content-body {
      max-width: 720px;
      margin: 0 auto;
    }
    & .page-level-mod-access-no-body-match {
      display:none;
    }
  }
  > .button-row {
    -ms-grid-column-span: 3;
    -ms-grid-column: 1;
    -ms-grid-row: 3;
    grid-area: footer;
    margin: auto 0;
    padding: 10px 20px;
    border-top: #000000 solid 1px;
    background: @zebra;
    & > button.floatleft {
      margin-left: 0;
    }
  }
}

.steps-wizard-icon {
  &.help-icon {
    background-image: url("../img/helpicon.svg");
  }
  &.close-icon {
    background-image: url("../img/close.svg");
  }
  &:hover {
    background-color: @header-link-color;
    border-radius: 50%;
    &.help-icon {
      background-image: url("../img/helpicon-hover.svg");
    }
    &.close-icon {
      background-image: url("../img/close-hover.svg");
    }
  }
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.fullscreen-steps-wizard {
  a.comphelppopup {
    position: relative;
    display: inline-block;
    color: #0066A5;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* The actual comphelppopup */
  span.comphelppopuptext {
    &:not(.concealed) {
      display: flex;
      flex-direction: column;
    }
    background-color: #ffffff;
    color: #444444;
    text-align: center;
    border-radius: 0px;
    position: absolute;
    z-index: 1;
    top: 40px;
    right: 0;
    min-width: 180px;
    margin-left: -100px;
    border-width: 1px;
    border-style: solid;
    border-color: #cccccc;
  }

  span div.comphelppopupHeader{
    background-color: #eeeeee;
    color: #707070;
    text-align: left;
    height: 16px;
    width: 100%;
    padding: 4px 0px 8px 0px;
    &>span {
      padding: 4px 4px 8px 14px;
    }
  }
  span div.comphelppopupLines{
    text-align: left;
    height: 16px;
    width:100%;
    padding: 4px 4px 8px 4px;
  }
}

/* UI Example Doc */
.ui-example .fullscreen-steps-wizard {
  & pre.code {
    overflow-x: auto;
    white-space: pre;
    background-color: @light-grey;
  }
  & li {
    list-style-type: none;
  }
}
/*YUI Reset */
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,select,textarea,p,blockquote,th,td { 
	margin:0;
	padding:0;
	font-size: inherit;
}
form{
	display:inline;
}
table {
	border-collapse:collapse;
	border-spacing:0;
}
fieldset,img { 
	border:0;
}
address,caption,cite,code,dfn,th,var,b {
	font-style:normal;
	font-weight:normal;
}
ol,ul {
	list-style:none;
}
caption,th {
	text-align:left;
}
h1,h2,h3,h4,h5,h6 {
	font-size:100%;
	font-weight:normal;
}
q:before,q:after {
	content:'';
}
abbr,acronym { border:0;
}
/* end reset rules*/
/* Reset 4J Styles */

td[bgcolor="#edf3fe"],
tr[bgcolor="#edf3fe"],
tr[bgcolor="#FFFFFF"],
tr.evenRow,
tr.oddRow,
table.linkDescList tr.evenRow,
table.linkDescList tr.oddRow,
table.grid tr.evenRow,
table.grid tr.oddRow,
table.linkDescList tr[bgcolor="#edf3fe"],
table.linkDescList  tr[bgcolor="#FFFFFF"] {
    /* bgcolor is a legacy zebra color set */
    /* color reset */
    background: none;
}
/* Reset 4J Styles */
/* reset fieldset old row zebra */
/* fieldset bgcolor clear */
fieldset {
    div.alt,
    div.oddRow,
    div.evenRow {
        background-color: transparent;
    }
}
strong {
	font-weight: bold;
}
em {
	font-style: italic;
}
.nocontent {
    color : #DE2B55;
    font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
    font-size: small;
    text-align: center;
}
body.admin-view{
.admin-view-header{
         font-size: 180%;
         font-weight: bold;
         line-height: 1.5em;
         font-family: Arial,sans-serif;
    }
.admin-breadcrumb {
   font-family: Arial,sans-serif;
       overflow: hidden;
       text-overflow: ellipsis;
       white-space: nowrap;
       flex: 1 1 auto;
       padding: 4px 0 0 8px;
       font-size: inherit;
       line-height: 2em;
       color: #444;
       margin-left: -7px;
}
a.admin-breadcrumb {
    color: #0066a5;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
    font-size: inherit;
    line-height: 2em;
    font-family: Arial,sans-serif;
    margin-left: -7px;
}
#tools2 {
    padding: 0;
    margin: 0;
    position: relative;
    }
#tools2 li a {
    padding: 0 5px;
    display: inline-block;
    width: 2.5em;
}

 height: 100px;
 overflow-y: scroll;
}

html:not([dir=rtl]) body.admin-view #pds-header {
    background: linear-gradient(to right, #0075DB, #00427C);
}
html[dir=rtl] body.admin-view  #pds-header {
    background: linear-gradient(to left, #0075DB, #00427C);
}

#seatingChart-header{
width:75px;
}

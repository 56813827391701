.counselorFilterDrop {
    width: 20%;
}

.counselorFilterText {
    width: 74%;
}

.counselorFilter {
    width: 100%;
}

.counselorcol1  {
    width: 68%;
}
.counselorcol2  {
    width: 16%;
    display: grid;
    text-align: center;
}

.counselorcol3  {
    text-align: center;
    width: 10%;
}

.counselorSelected,
.counselorSelected:focus
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 12px;
    
    position: static;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;

    /* Common/BTN Default Primary */
    border: 1px solid #0075DB;
    background: #0075DB;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
}

.counselorNotSelected,
.counselorNotSelected:focus

{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 12px;
    
    position: static;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    
    /* Common/BTN Default White */

    background: #FFFFFF;
    /* Common/BTN Default Primary */

    border: 1px solid #0075DB;
    box-sizing: border-box;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
    color: #0075DB;
}

.primarycounselor {
    margin-bottom: 3px;
}

input.counselorStudentFilterInput {
    width: 200px;
    &[type="number"] {
        height: 22px;
    }
}

.counselor-assignment-logic {
    padding-bottom: 10px;
    & > div {
        margin: 0 10px 0px 10px;
        
    }
    & > div:nth-child(even) > div {
        background-color: @zebra;
    }
    & > div:nth-child(odd) > div {
        background-color: @zebra-alt;
    }
}

#counselor_dialog pds-icon {
    width: 18px;
    height: 18px;
    fill: #0075db;
    vertical-align: middle;
}
.counselor-student-selection {
    margin: 0 20px 10px 20px;
}


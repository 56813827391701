#edit-standard {
  fieldset>div>label+span {
    white-space: normal;
  }
  a.description-collapse {
    margin-left: 10px;
  }
  .nopadding-row {
    padding: 0
  }
}

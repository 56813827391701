label.incidentAttendance-action-code-label {
    margin-left: 10px;
}
select.incidentAttendance-multi-select {
    display: inline;
    max-width: 500px;
    overflow: auto;
    min-width: 250px;
}
select.incidentAttendance-default-code {
    width: 350px;
}
.multiselect-box-fluid-form {
    background-color: #ffffff !important;
    display: flex;
}
.incidentAttendance-grid-container {
    margin-top: 10px;
}
.incidentAttendance-drawer td.grey-out {
    background-color: @light-med-grey;
}
.incidentAttendance-drawer .clock-inout.button {
    margin: 0;
}
.video-group,
.psHelp .video-group {
  .flex-display();
  .flex-direction();
  .flex-wrap(wrap);
  .justify-content(space-between);
  .align-items();

  margin: 20px;
  width: calc(~"100% - 40px");
  h2 {
    margin: 20px 0;
    .flex(0 0 100%;);
  }
  .video-element {
    .flex-display();
    .flex-direction(column);
    .flex(0 1 calc(~"50% - 32px"));
    margin-bottom: 20px;
    border: @border-style;
    border-radius: @border-radius-med;
    padding: 10px;
    .column-1 {
      display: block;

    }
    .column-2 {
      display: block;

    }
    h3 {
      background-color: transparent;
      margin: 10px 0;
      padding: 0;
    }
    p {
      margin: 0;
      margin-top: 0;
    }
    &:first-child {
      margin-right: 0;
      .flex-direction(row);
      .flex(1 0 calc(~"100% - 22px"));
      h3 {
        background-color: transparent;
        margin: 0 20px 20px;
        padding: 0;
      }
      p {
        margin: 20px;
        margin-top: 0;
      }
      .column-1 {
        width: 60%;
      }
      .column-2 {
        display: inline-block;
        width: 40%
      }
    }
    .video-wrapper {
      height: 0;
      margin: 0;
      padding-bottom: 56.25%;
      position: relative;
      border-radius: @border-radius-med;
      overflow: hidden;
      iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }
}

.banner-wrapper {
  display: none;
}

.new-experience-banner-wrapper {
  max-width: 400px;
  height: 75px;
  border: 1px solid #ccc;
  border-top-left-radius: 38px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 38px;
  padding-left: 80px;
  padding-right: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
  background-repeat: no-repeat;
  background-position: left center, right center;
  overflow: hidden;
  text-overflow: elipsis;
  color: #444;
  background-color: #fff;
  &:focus {
    box-shadow: 0 0 0 1px #11aaeb;
    outline: none;
  }
}

.new-experience-banner-title {
  font-weight: bold;
  display: block;
}

.new-experience-banner-text {

}

@media screen and (max-width: 980px) {
  .banner-wrapper {
    margin-left: 0;
    left: 280px;
  }
}

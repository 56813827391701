.pssAutoComplete {
  width: auto;
  div {
    border: 1px solid #a1a1a1;
    overflow-y: scroll;
    height: auto;
    max-height: 200px;
    max-width: 400px;
  }
  input {
    height: 2.0em;
    width: 250px;
  }
  ol {
    width: inherit;
    margin: 0;
    li {
      cursor: pointer;
      list-style: none;
      margin: 0px;
      width: auto;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 5px;
      padding-bottom: 5px;

      &:nth-of-type(odd){
        background-color: @zebra;
      }

      &:nth-of-type(even){
        background-color: @zebra-alt;
      }

      &.highlighted {
        background: none repeat scroll 0 0 @light-med-blue;
        border-color: @dark-blue;
      }
    }

  }

}
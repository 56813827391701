#course-selection-form {
  .feedback-note,
  .feedback-caution {
    margin: 10px;
  }

  .course-selection-container {
    margin: 10px;
  }

  #table-courses {
    .column_selection {
      width: 50px;
      text-align: center;
    }

    .column_course_number {
      width: 160px;
    }
  }
}

.fitness-div-height {
  max-height:270px;
}

.fitness-studentfitness-textareas {
  max-width: 515px;
  width: 515px;
  height: 65px;
}

#addEditStudentFitness {
  label {
    padding: 0;
  }
  .student-name-header {
    border-bottom: 0
  }
  #studentName {
    margin-right: 10px;
  }
}

#lbl_nextButton,#lbl_RefreshButton {
  font-weight: normal;
}

#current-selection-span {
  padding-left: 5px;
}

.fitness-drawer-student-name {
  margin: 0 12px 12px;
  padding: 2px 2px 2px 5px;
  max-width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  span {
    margin-right: 0;
  }
}
.template-grid-table {
	tr th:first-child {
		width: 1%;
	}
	tr th:nth-child(2) {
		width: auto;
	}
	tr th:nth-child(3) {
		width: auto;
	}
	tr th:last-child {
		width: 1%;
	}
}

.template-grid-table-order {
	tr th:first-child {
		width: 6em;
		min-width: 6em;
		max-width: 6em;
	}
	tr th:nth-child(2) {
		width: auto;
	}
	tr th:nth-child(3) {
		width: auto;
	}
	tr th:last-child {
		width: 1%;
	}
	tr td:first-child {
		white-space: nowrap;
	}
	input[type="text"] {
		width: 3em;
	}
}

.template-grid-table-edit {
	tr th:first-child {
		width: 1%;
	}
	tr th:nth-child(2) {
		width: 1%;
	}
	tr th:nth-child(3) {
		width: auto;
	}
	tr th:nth-child(4) {
		width: auto;
	}
	tr th:last-child {
		width: 1%;
	}
}

.template-header {
	margin-right: 0px;
	min-width: 480px
}

.duplicate-template-name {
	margin-left: 20px;
	height: 28px;
	width: 90%;
}
.template-discription-ellipsis {
		max-width: 20em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
	}
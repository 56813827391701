.box-round h2 {
  &.careertech-padding {
    padding-right: 40px;
  }
  &>button.careertech-locality-button {
    bottom: 0.5em;
  }
}

div.careertech-reimbursement-page-filters {
  display: flex;
  margin: 0 20px 10px 20px;
  label {
    margin-right: 10px;
  }
}

div.careertech-reimbursement-grid-total {
  font-size: 110%;
  margin: 0 10px 5px 0;
}

form.careertech-reimbursement-drawer {
  & h3.careertech-reimbursement-name {
    margin: 10px;
    max-width: 538px;
  }
}

.careertech-summary-container {
	display: flex;
	margin-left: 20px;
	flex-wrap: wrap;
	justify-content: space-between;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
    .checkmark-icon em {
    	vertical-align: middle;
    }
}

.careertech-overflow > div:not(.gridWidget) {
  overflow: auto;
}

div.careertech-setup-change-history {
  text-align: right;
  margin: 0 20px 10px 20px;
}

button.careertech-list-button {
    font-size: 14px;
}

.careertech-list-section {
    margin: 0 20px 20px 20px;
    border: @border-style;
    border-radius: 4px;
    h2 {
        margin: 20px 10px 10px;
        padding-top: 5px;
    }
    div.careertech-list-item-container {
        display: flex;
        flex-direction: column;
        max-height: 300px;
        overflow: auto;
        div.careertech-list-item {
            margin: 0 10px 10px 10px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            padding: 0 5px;
            &:nth-of-type(odd) {
                background-color: @zebra;
            }
            &:nth-of-type(even) {
                background-color: @zebra-alt;
            }
            &>span {
                .ellipsis;
            }
            &>div {
              display: flex;
              align-items: center;
            }
        }
        span.careertech-list-no-items{
            margin: 0 10px 10px 10px;
        }
    }
}
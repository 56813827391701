/**
* GridWidget styles
*/
/* pearsonstrap specific styles*/
fieldset.filterBox {
  border:none;
  padding-bottom: 5px;
  margin: 3px 4px;
}

fieldset.filterBox.active {
  padding-bottom: 0px;
}

fieldset.filterBox > div,
fieldset.filterbox > div:not(.button-row) {
  margin: 0;
  padding: 8px 5px;
  .flex-display;
  .flex-direction;
  .flex-wrap;
  .justify-content(flex-start);
  .align-items(flex-start);
}

fieldset.filterBox > div > .flex-fixed25 {
  .flex(0 0 25%);
  margin-right: 10px;
}

fieldset.filterBox > div > label.flex-fixed25 {
  .flex(0 0 calc(~"25% - 10px"));
}
fieldset.filterBox > div > div > select.fixed95 {
  width: 95%;
  max-width: 100%;
}
fieldset.filterBox > div > .comparator,
fieldset.filterBox > div > div .comparator {
  .flex(0 0 140px);
  min-width: 140px;
  margin-right: 10px;
}


fieldset.filterBox > div > span.validation-container {
  .flex-display;
  .flex(1 1 auto);
  margin-right: 10px;
  padding-left: 0;
  border:none;
}

fieldset.filterBox input[type="checkbox"] {
  .flex(0 0 auto);
  .flex-grow(0);
  margin-right: 10px;
}

fieldset.filterBox > div > span.validation-container > input {
  margin-right: 0;
}

fieldset.filterBox > div > span.validation-container > input.flex-date {
  .flex(0 0 100px);
}

fieldset.filterBox > div > span {
  .flex-display;
  .flex(1 1 auto);
  padding-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
  border: 1px solid lightgray;
}

fieldset.filterBox > div {
  & > label, input, select {
    .flex(1 1 auto);
    margin-right: 10px;
  }
  & select.units {
      .flex(0 1 auto);
      margin-right: 10px;
  }
  & > div {
    .flex-display;
    // this dropdown/select to be in use with field selector (25%) and 2 buttons (30px each);
    // there are also 10px margins. When all is taken into account the auto evaluates to
    // (100% - 25% - 60px [buttons] - 30px [margins]) or calc(~"75% - 90px").
    // 88px works best for IE and Firefox, 96px works best for Chrome and Safari, using 92px as a compromise for now
    // IE does not support calc inside a flex statement - going back to auto and adding max-width
    .flex(1 1 auto);
    max-width: calc(~"75% - 88px");
    margin: 0 10px 0 0;
    padding: 0;
	flex-wrap: wrap;
    select {
      .flex(1 1 100%);
    }
  }
}

fieldset.filterBox .error-message {
    margin-left: 2px;
    align-self: center;
}

fieldset.filterBox div:first-of-type label.toLabel {
    display: inline-block;
    padding: 0 5px;
}
/* End Search Inputs*/

/**
 * Filter Box (4th div): Limit To check boxes
 */
/* Checkboxs div*/
fieldset.filterBox.active > div > div:nth-of-type(4) {
    display: block;
}

/* Filter check box labels*/
fieldset.filterBox > div > div:nth-of-type(4) span label {
    font-weight: normal;
    width: calc(~"100% - 16px");
}

/* Label for Check Box divs*/
fieldset.filterBox > div > div:nth-of-type(4) label{
    margin: 0;
    /*  padding: 0 20px 0 0; */
    padding: 0;
    display: inline-block;
    white-space: normal;
}

/* Filter check box spans*/
fieldset.filterBox > div > div:nth-of-type(4) span {
    white-space: nowrap;
    display: inline-block;
    width: 145px;
    vertical-align: top;
}

/* Filter column check boxes*/
fieldset.filterBox > div > div:nth-of-type(4) span [type="checkbox"]{
    margin: 3px 3px 0 0;
    vertical-align: top;
}

fieldset.filterBox > div > div:nth-of-type(4) {
    border-top: 1px solid #DDD;
}
/* End Limit To check boxes*/

fieldset.filterBox button:last-of-type {
    margin-right: 0;
}

.gridWidget .grid-header {
    margin-right: 10px;
    margin-left: 10px;
    .flex-display;
}

.gridWidget .grid-header label {
    padding: 0;
    .flex(1);
}

.gridWidget .customGridMessages {
    .flex(1);
    text-align: right;
}

.gridWidget .customGridMessage {
    margin-left: 10px;
}

.gridWidget .header-filter {
   margin: 0px 10px 0px 10px;
    background-color: @med-blue;
    background-position: 12px center;
    color: #FFF;
    border: 0;
    border-radius: 3px 3px 0px 0px;
    .flex-display;
    .flex-direction;
    width: 100%;
 
    label {
        margin: 4px 10px 0 0;
        white-space: nowrap;
    };
    input {
        margin: 1px 4px 1px 4px;
        width: 90%;
        height: 1.2em;
    };
    button {
        margin: 2px 5px 2px 5px!important;
    };
}

.bottom-box-round {
    border: 1px solid #ccc;
    border-radius: 0 0 4px 4px;
    margin: 0 10px 10px;
    padding-top: 10px;
}


.gridWidget.box-round .filter-bar {
    margin: 0;
    background-color: @med-blue;
    background-position: 12px center;
    color: #FFF;
    border-radius: 3px 3px 0px 0px;
    .flex-display;
    .flex-direction;
    line-height: 2em;
    margin-bottom: 10px;
}

.gridWidget.box-round .filter-bar.collapsed {
    border-radius: 3px;
    margin-bottom: 0;
}


.gridWidget .heading-text {
    .flex(0 0 auto);
    padding-left: 10px;
    padding-right: 10px;
}

.gridWidget .filter-bar {
    .flex(1);
    .align-self(flex-start);
}

.gridWidget .filter-bar .parameters {
    .flex-display;
    .flex(1 0 auto);
    .justify-content(flex-end);
    padding-right: 10px;
}

.gridWidget .filter-bar .parameters span {
    padding: 0 3px;
    max-width: 80px;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.gridWidget .filter-bar .parameters span:last-of-type {
    max-width: 400px;
}

.gridWidget fieldset {
    margin: 5px;
}

.gridWidget .tableHead.body-has-scroll-bars{
    padding-right: 16px;
}

.gridWidget .tableHead {
    margin-right: 10px;
    margin-left: 10px;
    background-color: @light-med-blue;
}

.gridWidget .tableHead table{
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    width: calc(~"100% - 16px");
}

.gridWidget .tableBody {
    margin-right: 10px;
    margin-left: 10px;
    height: auto;
}

.gridWidget .tableBody table{
    margin-right: 0;
    margin-left: 0;
    width: 100%;
}

.gridWidget .scrollContent{
    max-height: 255px;
    overflow-y: auto;
    padding: 0;
    margin: 0 10px 10px 10px;
    border: 1px solid  @border-color;
}

.gridWidget .scrollContent table{
    width: 100%;
    margin: -1px 0 -1px -1px;
}

.gridWidget .scrollContent table td span {
    white-space: normal;
}

.dateWidget input {
    width: 6em;
}

.gridWidget .sortable.sortAscending em {
    padding: 0px 1px 0px 2px;
}

.gridWidget .sortable.sortDescending em {
    padding: 0px 1px 0px 2px;
}

.gridWidget .sortable em {
    position: absolute;
    top: calc(~"50% - 10px");
    right: 1px;
}

.gridWidget th,
.gridWidget .table th,
.gridWidget td.th {
    vertical-align: middle;
}

.gridWidget td.number,
.gridWidget .table td.number{
    text-align: center;
}

.gridWidget .sortable span {
    display: block;
    position: relative;
    padding:0 18px 0 18px;
}

.gridWidget .sortable.left span {
    padding:0 18px 0 0;
}

.gridWidget .sortable {
    color: darken(@link-color, 10%);
}

.gridWidget .sortable:hover{
    cursor: pointer;
    color: @link-color-hover;
}

.gridWidget .sortable ::selection {
    background: transparent;
}

.gridWidget fieldset .multi-select-dropdown {
    margin-right: 15px;
    .multi-select-dropdown-container {
        position: relative;
        flex-grow: 1;
        .dropdown-menu {
            position: absolute;
            top: 24px;
            max-height: 400px;
            max-width: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            z-index: 100;
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 2px;
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
            background-clip: padding-box;
            .dropdown-menu-items {
                display: flex;
                flex-direction: column;
                min-width: 160px;
                padding: 5px;
                > .dropdown-menu-item {
                    display: inline-flex;
                    flex-direction: row-reverse;
                    flex-wrap: nowrap;
                    white-space: nowrap;
                    text-align: left;
                    padding: 5px 10px;
                    background-color: #fff;
                    border: none;
                    input {
                        align-self: center;
                        margin: 0;
                        margin-left: 10px;
                        width: auto;
                    }
                    label {
                        align-self: center;
                        width: 100%;
                        line-height: 1em;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    &:hover {
                        background-color: #f1f4f4;
                    }
                    &:focus {
                        outline-style: none;
                    }
                }
            }
        }
    }
}

.gridWidgetPagination {
    text-align: center;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    text-align: center;
}

.gridWidgetPagination ul {
    display:inline-block;
    margin: 0;
}

.gridWidgetPagination ul li {
    display:inline;
}

.gridWidgetPagination a {
    display: inline-block;
    min-width: .75em;
    text-align: center;
    border-radius: @border-radius-med;
    padding: 0 0.4em;
}

.gridWidgetPagination ul li.active a {
    background-color: @med-blue;
    color: #FFFFFF;
    &:hover {
        background-color: @med-blue;
    }
}

.gridWidgetPagination ul li a,
.fieldList ul li a {
    display: inline-block;
    padding: 0 5px;
    border-radius: 5px;
    margin: 2px 5px;
}

.gridWidgetPagination ul li a:hover {
    background-color: #B2D0E9;
}
.gridWidgetPaginationDisable{
    pointer-events: none;
}

/* Landscape phone to portrait tablet */
/* @media screen and (max-width:767px) { */
@media screen and (max-width:768px) {
    header:after{
        // content:"Landscape phone to portrait tablet";
        margin:8px;
    }
}

/* Landscape phones and down */
/* @media screen and (max-width:480px) { */
@media screen and (max-width:767px) {
    header:after{
        // content:"Landscape phones and down";
        margin:8px;
    }

    fieldset.filterBox > div > div {
        //display: block;
        //padding: 4px 0;
        border-top: 1px solid #DDD;
    }

    fieldset.filterBox > div > div:first-of-type {
        border-top: none;
    }

    fieldset.filterBox div:first-of-type label {
        display: inline-block;
    }

    fieldset.filterBox div > div > label{
        margin: 0;
        padding: 0;
        width: 22%;
        vertical-align: top;
    }

    fieldset.filterBox > div > div:first-of-type input {
        width: calc(~"78% - 14px");
    }

    /* label "to" */
    fieldset.filterBox label.toLabel {
        margin: 0;
        padding: 0 5px;
        width: calc(~"6% - 10px");
    }

    /* Date Widget Span*/
    fieldset.filterBox span.dateWidget {
        margin: 0;
        padding: 0;
        width: 36%;
    }

    /* Date Widget Input*/
    fieldset.filterBox span.dateWidget input {
        width:calc(~"100% - 52px");
    }

    /* Date Widget Button*/
    fieldset.filterBox span.dateWidget button {
        width:40px;
    }

    fieldset.filterBox > div > div:nth-of-type(4) div{
        width: 78%;
        display: inline-block;
        padding: 0;
    }
}
/* End of Media Query */
/**
* END of GridWidget styles
*/


 .asset-inventory {
	.fluid-form>div.box-round {
		display: block;
		div>div>div {
		    padding-left: 5px;
		}
	}
	.fluid-form>div>div>div span{
	    padding-left: 5px;
	    display: block;
	}
	.fluid-form>div:not(.button-row)>div>input[type=text] {
        width: auto;
        width: ~"calc(100% - 20px)";
    }

	.fluid-form>div:not(.button-row)>div>textarea {
		width: auto;
		width: ~"calc(100% - 18px)";
	}
	.fluid-form>div:not(.button-row)>div>select {
		width: 95%;
	}
	.fluid-form {
		h2,
		h3,
		h4 {
			margin-left: 10px;
			margin-right: 10px;
		}
        div.fluid-one-line-note {
            //used for radios and checkboxes
            display: flex;
            width: 95%;
            flex: 1 1 auto;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: nowrap;
            >div:not(.fluid-one-line-note) {
                width: 94%;
                flex-wrap: wrap;
                display: flex;
                flex: 1 1 auto;
                align-items: flex-start;
                margin-top: -5px;
                margin-left: 30px;
                input[type="radio"],
                input[type="checkbox"] {
                    margin: .3em 5px 0 0;
                }
                label {
                    padding: 0 0 0 5px;
                }
                textarea {
                    width: 97%;
                    max-width: 97%;
                }
            }
        }
		div.fluid-one-line {
			//used for radios and checkboxes
			display: flex;
			width: 95%;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			flex-wrap: nowrap;
			>div:not(.fluid-one-line) {
			    width:100%;
			    flex-wrap: wrap;
				display: flex;
				align-items: flex-start;
				margin-top: -5px;
				input[type="radio"],
				input[type="checkbox"] {
					margin: .3em 5px 0 0;
				}
				textarea {
				width: 97%;
				max-width: 97%;
				}
				 label {    padding: 0 0 0 5px; }

			}
		}
        div.fluid-one-line-checkbox {
                //used for radios and checkboxes
                display: flex;
                width: 95%;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: nowrap;
                padding-left: 4px;
                padding-top: 0px;
                >div:not(.fluid-checkbox-line) {
                    width:100%;
                    flex-wrap: wrap;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    margin-top: 5px;
                    padding-top: 0px;
                    input[type="radio"],
                    input[type="checkbox"] {
                        margin: .3em 5px 0 0;
                    }
                    textarea {
                    width: 100%;
                    }
                     label {    padding: 0 0 0 5px; }

                }
            }
		&>div {
			//fluid rows
			display: flex;
			align-items: flex-start;

			&>div {
				//fluid row blocks
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				flex-basis: 25%;
				position: relative;
				label {}

				em:nth-child(3) {
				    position: absolute;
					top: 16px;
					right: -1px;
                    a {font-size: medium;
                       margin-right: -5px;}
                    font-style: normal;
				}

                b + em:nth-child(2) {
                                    position: relative;
                                    font-size: 150%;
                                    right: -3px;
                                    a {font-size: medium;
                                       margin-right: -5px;}
                                    font-style: normal;
                                }
                b + em:nth-child(3) {
                                    position: relative;
                                    top: -3px;
                                    right: 3px;
                                    a {font-size: medium;
                                       margin-right: -5px;}
                                    font-style: normal;
                                }

				&>div {
					em {
						position: relative;
						top: -3px;
						right: 3px;
						a {font-size: medium;
                           margin-right: -5px;}
						font-style: normal;
					}
				}
			}
			&>div:not(.fluid-one-line):nth-child(2) {
				select+em {
					font-style: normal;
				}
			}
		}
		fieldset{
            padding: 10px;
            width: calc(100% - 11px);
            div {
               :nth-of-type(odd):not(.button-row) {
                   background-color: #fff;
               }
               background-color: #fff;
               display: flex;
            }
            div>div> {
               background-color: #fff;
               display: inline-grid;
               flex-grow: 1;
            }
        }
        fieldset label, fieldset p.label {
            display: inline-block;
        }
        div.box-round {
            display: flex;
        }
        >div>div>span input[type="checkbox"] {
            margin-left: 250px;
        }
        &>div>div> textarea {
            overflow-y:auto;
            height: 100px; width: 450px
        }
		input {
			line-height: 1.5em;
			font-size: 1.3em;
		}
		input+em,
		select+em,
		textarea+em,
		.ui-datepicker-append+em,
		.ui-datepicker-trigger+em {
			font-size: 150%;
			margin-left: 5px;
			vertical-align: top;
		}
		@media screen and (-webkit-min-device-pixel-ratio:0) {
			/*safari and chrome*/
			select {
				height: 32px;
				line-height: 32px;
			}
		}
		.error-message {
			margin: 0;
		}
	}
	.fluid-form>div:not(.button-row):nth-of-type(odd):not(.button-row) {
		background-color: #fff;
	}
    .fluid-form>fieldset>div:nth-of-type(odd):not(.button-row){
        background-color: #fff;
        padding-left: 10px;
    }
	.step-content {
		padding: 0 ~"calc(50% - 360px)";
		max-height: calc(~"97vh - 100px");
	}
	fluid-form>div:not(.button-row)>div {
        margin-left: 11px;
            margin-right: 5px;
            margin-top: -5px;
            margin-bottom: 5px;
    }
    .fixed-size{
      overflow:auto;
      resize:none;
    }
    .vertical-aligned{
       vertical-align:sub;
     }
   .text-center{
     text-align:center;
   }
   .action-column{
     width:1px;
   }
   #ownershipHistoryId {
     margin: 0px 10px 0px -10px;
   }

   .div-action {
     display: flex;
     margin-left: 5px;

     & > div {
       margin-right: 5px;
     }
   }

   h4.expanded+div.ownershipHistoryClass{
     margin-right: 0px ;
   }

   #inventory_tag{
      width: ~"calc(100% - 24px)";
   }
}
 #contextDivId{
   display: inline;
 }
 #addInventory{
   float:right;
   margin-right: 1%;
 }
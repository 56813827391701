.multi-submit-form{
    textarea {
        margin: 0px;
        width: 97%;
        max-width: 97%;
        height: 50px;
        overflow-y: auto;
    }
    label+p {
        padding-left: 18px;
    }
    select {
        width: 97%;
    }
    .padding-left {
        padding-left: 25px;
    }
    .border-bottom {
        border-bottom: 1px solid #ccc;
    }
    .header-left {
        font-size: 1.17em;
        font-weight: bold;
        margin-left: 0px;
        margin-top: 10px;
        text-align: left;
        width:58%;
        display: inline-block;
    }
    .header-right {
        font-size: small;
        text-align: right;
        display: inline-block;
        width: 38%;
        position: relative;
        word-break: break-word;
        vertical-align: top;
        margin-top: 10px;
        margin-right: 0px;
    }
    .collapse-header-left {
        font-size: 1.17em;
        font-weight: bold;
        margin-left: 5px;
        margin-top: 5px;
        text-align: left;
        width:50%;
        display: inline-block;
        position: relative;
    }
    .collapse-header-right {
        font-size: small;
        text-align: right;
        display: inline-block;
        width: 40%;
        position: relative;
        word-break: break-word;
        vertical-align: top;
        margin-top: 5px;
        margin-right: 5px;
    }
    .box-round>div:not(.feedback) {
        margin: 0 10px 10px 10px;
        label {
            padding: 0 0 0 5px;
        }
    }
    .flex-readonly {
        display: flex;
        >div {
            padding-left: 5px;
        }
    }
    .multi-search-area {
        textarea {
            margin: 0 10px 0px 20px;
            width: 93%;
            max-width: 93%;
        }
    }
    .round-box {
        margin: 0 10px 10px 10px;
        border: 1px solid #ccc;
            border-top-color: rgb(204, 204, 204);
            border-top-style: solid;
            border-top-width: 1px;
            border-right-color: rgb(204, 204, 204);
            border-right-style: solid;
            border-right-width: 1px;
            border-bottom-color: rgb(204, 204, 204);
            border-bottom-style: solid;
            border-bottom-width: 1px;
            border-left-color: rgb(204, 204, 204);
            border-left-style: solid;
            border-left-width: 1px;
            border-image-source: initial;
            border-image-slice: initial;
            border-image-width: initial;
            border-image-outset: initial;
            border-image-repeat: initial;
    }
    .margin {
        margin-left: 10px;
        margin-right: 10px;
    }
}
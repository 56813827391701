/* Variables */
@dark-blue: #042d40;
@med-blue: #195f7d;
@light-med-blue: #a3bfcc;
@light-blue: #d9edf7;
@lightest-blue: #E7EFF3;
@nav-background: #E6EFF2;
@nav-box: #DAE2E5;
@common-navy: #0075DB;

@pds-font-family: Open Sans, sans-serif;

@header-background: #00427C;
@header-link-color: #FFF;

@breadcrumb-background: #00427C;
@breadcrumb-highlight: #3870B7;
@breadcrumb-link-color:#FFF;
@breadcrumb-link-hover-color:#6ECFF9;

@psdialog-title-background: #F7F7F7;
@psdialog-title-color: #000;
@psdialog-buttonbar-background: #FFF;
@psdialog-borderline-color: #E0E0E0;

@button-color: #0066A5;
@button-hover-color: #00427C;
@button-inactive-color: #E5E5E5;
@button-hover-inactive-color: #F4F4F4;

@link-color: #0066A5;
@link-color-hover: #00427C;
@zebra: #EEEEEE;
@zebra-alt: #FFFFFF;

@text-color: #444444;

@dark-grey: #444444;
@med-grey: #666666;
@light-med-grey: #dddddd;
@light-grey: #EDEDED;

@outline-color: lighten(saturate(@med-blue, 20%), 20%);

@border-color: #CCCCCC;
@border-style: 1px solid @border-color;
@border-radius-small: 2px;
@border-radius-med: 4px;
@border-radius-circle: 50%;

//Color palette
@palette-blue-1: #5a91d6;
@palette-purple-1: #8b41b2;
@palette-orange-1: #ff9e33;
@palette-green-1: #13baa0;
@palette-grey-1: #64635f;
@palette-green-2: #5db546;

//Alert/Notification Color palette
@alert-default: #f4800b;
@alert-success-foreground: #3C763D;
@alert-success-background: #DFF0D8;
@alert-info-foreground: #075B7F;
@alert-info-background: #D9EDF7;
@alert-warn-foreground: #B25900;
@alert-warn-background: #FCF8E3;
@alert-danger-foreground: #A94442;
@alert-danger-background: #F2DEDE;
@alert-text: #38383e;

@ps-icon-size: 30px;

.button-style {
    background-color: @button-color;
    background-position: left bottom;
    background-repeat: repeat-x;
    border: 0 none;
    border-radius: @border-radius-med;
    color: #FFFFFF;
}
.button-style-hover {
    background-color: @button-hover-color;
    color: #FFFFFF;
}
.inactive-button-style {
    background-color: @button-inactive-color;
    border: 1px solid #454545;
}
.inactive-button-style-hover {
    background-color: @button-hover-inactive-color;
    border: 1px solid #454545;
}
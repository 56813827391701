.greyBackForGrad {
    background-color: #eee;
}

.editPlacementButton {
    float:right;
}

.graduationTitleSelect {
    min-width: 100px; 
    max-width: 500px;
}

.graduationEditPlacementTableContainer {
    display: block;
    max-height: 800px;
    max-width: 800px;
    overflow: scroll;
}

.graduationGridDefault {
    background-color: #448dce;
     color:white 
}

.graduationGridEditTestNodeColorText {
    color: white
}
.graduationGridTestNodeColorDefault {
    color: #0066a5
}

div.col-3.graduationEdiitDrawer {
    width: 30%
}

div.col-3.graduationEdiitDrawer input,
div.col-3.graduationEdiitDrawer select {
    min-width: 140px;
    width: 140px;
}

.greyBackForGrad {
    background-color: #eee;
}

.gplist-fixed-column-version {
    width: 100px;
    max-width: 100px;
}

.gplist-fixed-column-studentlink {
    width: 162px;
    max-width: 162px;
}

.gplist-fixed-column-button {
    width: 28px;
}

.gplist-border-bottom {
    border-bottom: 1px solid #ddd;
}

.flexlinebox {
    display: flex;
    align-items: baseline;
}

.flexlineitem {
    flex:auto;
}

.flexlastitem {
    flex: 0 0 auto;
    white-space: nowrap;
}

/*
 * This less block is primarily used on gradplanner.
 * However, it is also used in ACP (CTE) on the Program Requirements step.
 * If tweaks are made check both areas.
 * If used outside of gradplanner, refactor to generalize or diverge the less block.
 */
.gpversion {
    .gpversion-group-container {
        padding-inline-start: 10px;
        margin-top: 5px;
        margin-bottom: 10px;
        border-inline-start-width: 6px;
        border-inline-start-style: solid;
    }

    .gpversion-depth-color0 {
        border-inline-start-color: #E5F3FF;
    }
    .gpversion-depth-color1 {
        border-inline-start-color: #C2E2FF;
    }
    .gpversion-depth-color2 {
        border-inline-start-color: #8FCBFF;
    }
    .gpversion-depth-color3 {
        border-inline-start-color: #5CB3FF;
    }
    .gpversion-depth-color4 {
        border-inline-start-color: #0075DB;
    }

    .gpversion-group-badge {
        border: 1px solid #0066A5;
        border-radius: 14px;
        padding: 0px 10px;
        line-height: 1.2em;
        color: #0066A5;
        flex: none;
    }

    .gpversion-group-header {
        line-height: 24px;
        padding-top: 20px;
        padding-bottom: 10px;
        padding-inline-end: 10px;
        border-bottom: 2px solid #00427C;
        display: flex;
        align-items: center;
        & > button {
            margin: 0px;
        }
        .gpversion-group-title {
            margin: 0 5px;
            flex: auto;
        }
    }

    .gpversion-node-row {
        padding: 10px;
        border-bottom: 1px solid #757575;
        display: flex;
        &.first {
            padding-top: 5px;
        }
        .gpversion-node-name {
            flex: auto;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            & > a {
                color: #0071B2;
            }
        }
        .gpversion-node-subject {
            color: #9D9D9D;
            flex: 0 0 200px;
        }
        .gpversion-node-detail {
            color: #7D7D7D;
            flex: 0 0 200px;
            text-align: right;
        }
    }
}

.grad-plan-multibutton-menu {
    div[id^="grad-plan-multibutton-popup-menu-"] {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 5px 1px;
        a {
            padding: 0 5px;
        }
        a:focus {
            background-color: @zebra;
        }
    }
}
button.grad-plan-selector-button {
    font-size: 14px;
}

div.grad-plan-selector-list {
    margin-top: 40px;
}

#grad-plan-list-table tbody tr.gplist-nozebra {
  background-color: @zebra-alt;
}

.grad-plan-selector-section {
    margin: 10px;
    border: @border-style;
    border-radius: 4px;
    h2 {
        margin: 0 10px 10px 10px;
        padding-top: 5px;
    }
    div.grad-plan-selector-item-container {
        display: flex;
        flex-direction: column;
        max-height: 300px;
        overflow: auto;
        div.grad-plan-selector-item {
            margin: 0 10px 10px 10px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            padding: 0 5px;
            &:nth-of-type(odd) {
                background-color: @zebra;
            }
            &:nth-of-type(even) {
                background-color: @zebra-alt;
            }
        }
        span.grad-plan-selector-no-items{
            margin: 0 10px 10px 10px;
        }
    }
}

.group-placement-container {
    width: 100%;
    max-height: 500px;
    overflow-y: auto;
    .group-placement-pathstring-label div.group-placement-arrow {
        display: inline-block;
        width: 14px;
        height: 11px;
        background-repeat: no-repeat;
        overflow: hidden;
        &.group-placement-expanded {
            background-image: url('../../images/listexpanded.gif');
        }
        &.group-placement-collapsed {
            background-image: url('../../images/listcollapsed.gif');
        }
        [dir=rtl] & {
            transform: scaleX(-1);
        }
    }
}
.group-placement-tree {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}
.group-placement-tree-item {
    list-style-type: none;
    padding-left: 15px;
}
.group-placement-tree-all {
    border-bottom: 2px solid #00427c;
    margin-right: 15px;
}

.group-placement-tree-item-selectable:hover {
    background-color: #ccc;
    color: black;
    cursor: pointer;
}
.group-placement-tree-item-selected {
    background-color: #448dce;
    color: white;
}

.group-placement-pathstring-label {
    margin: 0px 0px 0px 10px;
    font-weight: bold;
}

.no-border {
    border: none;
}

.grad-plan-selector-target-path {
    align-self: flex-end;
}
.promote-version {
    margin-left:10px;
    margin-right:15px;
}
.promote-copy-remove-data-capture {
    margin: 20px 20px 10px;
}
.promote-align {
    align: center;
}
.node-link-spacing {
    padding-inline-end: 3px;
    padding-inline-start: 3px;
}
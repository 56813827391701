

div[pss-audit-link].change-history-link {
  float: right;
  margin-top: 35px;
  margin-right: 30px;
}

select[id^=filter-field-audit-history-grid] {
  max-width: 150px;
}

table.grid.audit-history tr.selected>td,
table.audit-history tr.selected>td {
  background-color: @med-blue;
  color: white;
  & a {
    color: white;
  }
}

.history-dialog-link {
  font-size: 14px;
}

.audit-popup-container {
  overflow: auto;
  margin: 10px 10px 20px 10px;
  width: -webkit-calc(~"100% - 20px");
  width: calc(~"100% - 20px");
  height: -webkit-calc(~"100% - 30px");
  height: calc(~"100% - 30px");
}

.audit-popup-frame {
  border:none;
  width: -webkit-calc(~"100% - 10px");
  width: calc(~"100% - 5px");
  height: -webkit-calc(~"100% - 10px");
  height: calc(~"100% - 10px");
}

.audit-popup-textarea {
  margin: 10px 10px 20px 10px;
  width: -webkit-calc(~"100% - 20px");
  width: calc(~"100% - 20px");
  height: -webkit-calc(~"100% - 30px");
  height: calc(~"100% - 30px");
  resize: none;
  white-space: pre;
  overflow-wrap: break-word;
  overflow: auto;
}


#view-change-history-record-dialog,
#audit-history-dialog {
  .expanded {
    background-color: #a3bfcc;
  }
  .collapsed {
    background-color: #ededed;
  }
  .audit-data {
    margin-top: 10px;
  }
  .audit-info {
    margin: 5px;
    width: -webkit-calc(~"100% - 10px");
    width: calc(~"100% - 10px");
    table-layout: fixed;
    td {
      word-wrap: break-word;
    }
    td:nth-child(1) {
      font-weight: bold;
      width: 40%;
    }
    tr:nth-child(even) {
      background-color: @zebra;
    }
    tr:nth-child(odd) {
      background-color: white;
    }
  }
  .audited-records {
    td:nth-child(1) {
      font-weight: bold;
      width: 30%;
    }
    td:nth-child(2), td:nth-child(3) {
      width: 35%;
    }
  }
  .audit-details {
    margin: 5px;
    width: -webkit-calc(~"100% - 10px");
    width: calc(~"100% - 10px");
    table-layout: fixed;
    td {
      word-wrap: break-word;
    }
    tr:nth-child(1) {
      font-weight: bold;
    }
    tr:nth-child(even) {
      background-color: @zebra;
    }
    tr:nth-child(odd) {
      background-color: white;
    }
  }
  .audit-group {
    margin: 10px 5px;
    width: -webkit-calc(~"100% - 12px") !important;
    width: calc(~"100% - 12px") !important;
  }
  .audit-header-divider {
    width: -webkit-calc(~"100% - 12px") !important;
    width: calc(~"100% - 12px") !important;
  }
  .group-label {
    font-weight : bold;
    padding: 0;
    margin: 4px;
    vertical-align: text-top;
  }
  .audit-groupby-details {
    margin: 5px;
    width: -webkit-calc(~"100% - 10px");
    table-layout: fixed;
    td:nth-child(1) {
      font-weight: bold;
    }
    tr:nth-child(even) {
      background-color: white;
    }
    tr:nth-child(odd) {
      background-color: @zebra;
    }
  }
}

#category-select {
  max-width: 180px;
}

input[type="checkbox"].audit-multifield-popup-checkbox {
  margin: 5px;
}

label.audit-multifield-popup-label {
  font-weight: normal;
}
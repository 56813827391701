/* Attendance CICO Threshold Settings */
.cico-threshold-label {
    margin-top: 4px;
    margin-left: 10px;
}

.cico-threshold-value {
    margin-top: 4px;
}

.popup-box.attendance-popup-picker > div {
    top: -27px;
}

.popup-box.cico-edit-menu {
    & > div {
        right: -25px;
        width: 190px;
        top: 5px;
    }
    .cico-edit-record {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 5px;
        .cico-edit-record-button {
            margin-left: 5px;
        }
    }
}

td button.cico-menu-button, td button.cico-menu-button:first-of-type {
    margin-left: 5px;
}
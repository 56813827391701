/* Help Styles */
.psHelp {
  background-image: none;

  #container {
    margin: 0;
    padding: 10px 0 0 0;
    background: #FFF;
    box-shadow: none;
    width: 100%;
    min-width: 540px;
    max-width: 100%;
    border: none;
  }
  #legal {
    display: none;
  }
  #psHelpSearchResults table,
  #psHelpSearchResults td {
    border: none;
  }
  &#psHelpTOC p strong {
    font-size: 110%;
  }

  &#psHelpTOC .box-round p {
    border-bottom: 1px solid #CCC;
  }

  &#psHelpTOC p + ul {
    margin-bottom: 15px;
  }

  &#psHelpIndex .col3 ul {
    margin-left: 15px;
  }

  &#psHelpIndex .col3 li {
    margin: 0 0 5px 0;
  }

  &#psHelpIndex .col3 li:hover {
    background: #EDEDED;
  }

  #nav-psHelpIndex {
    text-align: center;
    border-bottom: 1px solid  @border-color;
  }

  .tools {
    position: absolute;
    top: 5px;
    right: 10px;
    margin: 0;
  }

  .tools .button {
    margin: 0 0 0 5px;
  }

  #nav-main,
  .psHelpTOCul {
    width: 100%;
    margin: 0 !important;
  }

  #nav-main li,
  .box-round .psHelpTOCul li {
    float: left;
    width: 100%;
    font-weight: bold;
    font-size: 100%;
    line-height: 2em;
    background-image: url("../img/icon-arrow-left.png");
    background-position: right -5px;
    background-repeat: no-repeat;
    list-style: none outside none;
  }

  .psHelpTOCul li a {
    display: block;
  }

  #nav-main li:hover
  .psHelpTOCul li:hover {
    background-position: right -65px;
  }

  h1 {
    text-indent: 10px;
  }


  h1,
  h2 {
    margin: 20px 0 10px 10px;
  }

  h3 {
    position: relative;
  }

  &.toc h3, &.toc h4, &.toc h5 {
    clear: left;
    line-height: 1em;
  }

  .box-round h1,
  .box-round h2,
  .box-round h3,
  .box-round h4,
  .box-round h5 {
    font-size: 110%;
    font-weight: bold;
    color: #000;
    margin: 10px 0 10px 0;
    padding: 5px 10px;
    position: relative;
    text-indent: 0;
    background-color: #E2E2E2;
    a {
      color: #000;
    }
    &:first-child {
      text-indent: 0;
      margin: 0 0 10px 0;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
    }
    span {
      position: absolute;
      top: 0;
      right: 10px;
      height: 100%;
      a {
        background-color: transparent;
        display: inline-block;
        line-height: 2em;
        color: @med-blue;
        position: relative;
        &:after {
          content: "";
          display: inline-block;
          width: 15px;
          height: 10px;
          background-image: url("../img/arrow-up-blk.svg");
          background-position: right center;
          background-repeat: no-repeat;
          //display: inline-block;
          //position: absolute;
          //top: 0;
          //right: 0;
        }
      }
    }
  }


  .helpToc {
    margin: 0 0 0 10px;
  }

  .helpToc li {
    margin: 0 0 10px 10px;
  }

  li.topic {
    font-weight: bold;
    margin: 15px 0 10px 0;
  }

  p {
    margin: 0 10px 10px;
  }

  table p {
    margin: 0 0 10px;
  }

  p:last-child {
    margin: 0 0 0;
  }

  .box-round ul,
  .box-round ol {
    margin-left: 35px;
    margin-right: 35px;
  }

  th {
    padding-left: 10px;
  }

  .box-round ul {
    list-style: disc outside none;
  }

  .box-round ol {
    list-style: decimal outside none;
  }

  ol + ul,
  ul + ol {
    margin-top: 20px;
  }

  .box-round li {
    margin: 0 10px 5px 0;
  }

  ul h2 {
    margin-left: -15px;
  }

  .col3 h2 {
    margin: 10px 10px 10px 0;
  }

  .col3 {
    border-left: 1px dotted #CCC;
    padding-left: 5px;
    width: 32%;
    float: left;
  }

  #nav-main,
  .psHelpTOCul {
    width: 100%;
    padding: 0;
  }

  #nav-main li,
  .psHelpTOCul li {
    text-indent: 10px;
  }

  #nav-secondary .button {
    margin: 0 10px 0 0;
  }

  input[type="text"],
  table {
    margin-left: 10px;
  }

  ol table,
  ul table {
    margin: 20px 0 19px 0;
  }

  p > b {
    margin: 0;
  }

  .box-round table {
    border: 1px solid  @border-color;
  }

  .box-round td {
    margin: 0;
    border: 1px solid  @border-color;
  }

  .box-round table p b {
    margin: 0;
  }

  ol p,
  ol p table {
    margin-left: 0;
  }

  table {
      width: -webkit-calc(~"100% - 80px");
      width: calc(~"100% - 80px");
      margin: 20px auto;
  }
  td[bgcolor="#eaeaea"] {
    background-color: @light-med-blue;
  }
  tr:nth-of-type(odd) {
    background-color: @zebra;
  }

  button.search {
    margin: 0 0 0 5px;
    height: auto;
  }
  #btnPrint {
    width: 28px;
    display: inline-block;
  }
  form[action="toc_search.html"] {
    display: block;
    padding-bottom: 10px;
  }
}
/* End Help Styles */

.healthplan-container {
    .widget-stepped-container {
       height: calc(~"97vh - 100px");
       display: -webkit-flex;
       display: -moz-flex;
       display: -ms-flexbox;
       display: -ms-flex;
       display: flex;
     }
     .widget-stepped-container .widget-steps-container {
       -webkit-flex: initial;
       -moz-flex: initial;
       -ms-flex: initial;
       flex: initial;
       min-width: 250px;
       max-width: 250px;
       overflow: auto;
     }
     .widget-stepped-container .widget-steps-container ul {
       margin: 20px 40px;
       list-style-type: none;
     }
     .widget-stepped-container .widget-steps-container ul li {
       width: 100%;
       line-height: 40px;
       list-style-type: none;
       position: relative;
     }
     .widget-stepped-container .widget-steps-container ul li a,
     .widget-stepped-container .widget-steps-container ul li span {
       width: 130px;
       overflow: hidden;
       white-space: nowrap;
       text-overflow: ellipsis;
       display: inline-block;
       vertical-align: middle;
     }
     .widget-stepped-container .widget-steps-container ul li:before {
       margin-left: -5px;
       margin-right: 10px;
       content: "";
       display: inline-block;
       width: 18px;
       height: 18px;
       border: 1px solid #ccc;
       border-radius: 50%;
       vertical-align: middle;
       background-color: #fff;
     }
     .widget-stepped-container .widget-steps-container ul li:after {
       content: "";
       position: absolute;
       top: 20px;
       left: 5px;
       right: auto;
       bottom: 0;
       display: inline-block;
       width: 1px;
       height: 40px;
       background-color: #ccc;
       z-index: -1;
     }
     .widget-stepped-container .widget-steps-container ul li:last-of-type:after {
       display: none;
     }
     .widget-stepped-container .widget-steps-container ul li.active:before {
       border: 1px solid #042d40;
       background-color: #042d40;
     }
     .widget-stepped-container .widget-steps-container ul li.completed:before {
       border: 1px solid #042d40;
       background-image: url("../img/icon-check-blue.svg");
       background-repeat: no-repeat;
       background-size: 70%;
       background-position: center;
     }
     .widget-stepped-container .widget-steps-container ul li.completed:after {
       background-color: #042d40;
     }
     .widget-stepped-container .widget-step-content {
       overflow: auto;
       padding: 0 20px 0 0;
       width: 100%;

       	.fluid-form {
        		h2,
        		h3,
        		h4 {
        			margin-left: 10px;
        			margin-right: 10px;
        		}
        		div.fluid-one-line {
        			//used for radios and checkboxes
        			display: flex;
        			width: 95%;
        			flex: 1 1 auto;
        			flex-direction: column;
        			justify-content: flex-start;
        			align-items: flex-start;
        			flex-wrap: nowrap;
        			>div:not(.fluid-one-line) {
        			    width:98%;
        			    flex-wrap: wrap;
        				display: flex;
        				flex: 1 1 auto;
        				flex-direction: row;
        				align-items: flex-start;
        				margin-top: 5px;
        				input[type="radio"],
        				input[type="checkbox"] {
        					margin: .3em 5px 0 0;
        				}

        				 label {    padding: 0 0 0 5px; }
        			}
        		}
        		&>div {
        			//fluid rows
        			display: flex;
                    align-items: flex-start;
        			&>div {
        				//fluid row blocks
        				display: flex;
        				flex-direction: column;
        				flex-grow: 1;
        				flex-basis: 25%;
        				position: relative;
        				label {}

        				em:nth-child(3) {
        				    position: absolute;
        					top: 16px;
        					right: -2px;
        				}
        				em:nth-child(2) {
                            position: absolute;
                            top: -5px;
                            right: -2px;
                        }
                        b + em:nth-child(2) {
                                            position: relative;
                                            top: 0px;
                                            right: 2px;
                                        }
                        b + em:nth-child(3) {
                                            position: relative;
                                            top: 0px;
                                            right: 2px;
                                        }

        				&>div {
        					em {
        						position: relative;
        						top: -3px;
        						right: 3px;
        					}
        				}
        			}

        		}
        		div>div>fieldset{
        		    padding: 10px; margin: 0px; width: calc(100% - 12px);
        		}
        		input {
        			line-height: 1.5em;
        		}
        		input+em,
        		select+em,
        		textarea+em,
        		b+em,
        		.ui-datepicker-append+em,
        		.ui-datepicker-trigger+em {
        			font-size: 150%;
        			margin-left: 5px;
        			vertical-align: top;
        		}
        		@media screen and (-webkit-min-device-pixel-ratio:0) {
        			/*safari and chrome*/
        			select {
        				height: 32px;
        				line-height: 32px;
        			}
        		}

        		.error-message {
        			margin: 0;
        		}


        }


       .fluid-form .autocomplete-combobox-container>span {
                  width: ~"calc(92% - 1px)"!important;
                  margin: 0 0 10px 5px;
       }
       .fluid-form>div:not(.button-row)>div>textarea {
            width: auto;
            width: ~"calc(100% - 12px)";
        }

       .fluid-form>div:not(.button-row)>div>input[type=text] {
           width: auto;
           width: ~"calc(100% - 20px)";
       }
       .fluid-form>div:not(.button-row)>div>select {
           width: auto;
           width: ~"calc(100% - 10px)";
       }

       .fluid-form>div:not(.button-row):nth-of-type(odd):not(.button-row) {
       		background-color: #fff;
       	}


       	.fluid-form .one-row {
                        flex-basis:100%;
        }
         .fluid-form .one-row-width {

                                 margin-right: 0;
                                 margin-top: 5px;

                                 width: 100%;

                                 textarea {
                                    width: auto;
                                    width: ~"calc(100% - 10px)";
                                 }
                                textarea.required+em {
                                  font-size: 150%;
                                  margin-left: -1px;
                                  vertical-align: top;
                                  top: 0;
                                  right: -4px;
                                  position: relative;
                                 }
                                 label {
                                    padding: 0;
                                 }
                   }

        .fluid-form>div.fluid-one-line>h4 {
            width: 100%
        }

        span.fluid-form {
            margin-left: 20px;
            margin-right: 20px;
        }
        .healthplan-container-hide {
            visibility: hidden;
        }

        .fluid-form .autocomplete-combobox-container .angucomplete-row {
            height: auto;
            min-height: 1em;
        }
     }

}

.health-plans-name {
  display: block;
  text-overflow: ellipsis;
  width: 450px;
  overflow: hidden;
  white-space: nowrap;
}

#lnk_readreceipts {
  margin-right: 0;
}
.plan_details_readOnly{
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin: 0px;
  width:100%;
  height: 900px;

  &>iframe {
    border:none;
    width:100%;
    height:100%;
  }
}

.health-plans-savas-input-container {
  margin-left: 10px;
};

.health-plans-savas-inputfield {
  min-width: 350px;
  margin-bottom: 20px;
};
/* Advanced Grad Plan Progress UI */

#detail-link-expanded {
  background: #FFF url("../img/arrow-down-blk.svg") no-repeat 3px center;
  padding-left: 16px !important;
  cursor: pointer;
}
	
#detail-link-collapsed {
  background: #FFF url("../img/arrow-right-blk.svg") no-repeat 3px center;
  padding-left: 16px !important;
  cursor: pointer;
  margin-bottom: 0;
}

.gp-progress {
  height: 25px;
  width: 100px;
  margin: auto;
}

.gp-progress-bar-container {
  margin-top:6px; 
  height: 12px; 
  display: inline-flex;
}

.gp-progress-table {
  th {
  	background: #f7f7f7;
  }
}

.gp-progress-row {
  border-bottom: 
  1px solid black;
  
  td {
    padding: 8px 5px;
  }
}

.gp-progress-waiver-span {
  float: right; 
  margin-right: 10px
}

#gp-progress-detail-controller-div, .single-gp-controller-div {
  margin-top: 0px;
  
  #detail-div {
    margin-left: 10px;
    
    #bc-div {
      border-bottom: 1px solid #ccc;
    }
    
    #detail-summary-div {
      margin-top: 15px; 
      margin-bottom: 15px;
      
      > h3 {
        margin-right: 20px; 
        border-bottom: none;

        > ul {
          margin: 0;
          
          > li {
            list-style-type: none;
            
            > img {
              margin-right: 5px;
            }
          }
        }
      }
    }
    
    #item-div, #min-requirement-div {
    
      > h4 {
        margin-left: 5px; 
        margin-right: 5px; 
        border-left: none; 
        border-top: none; 
        border-right: none; 
        border-bottom: 1px solid #CCC;
        
        > span {
          float: right; 
          margin-right: 25px
        }
      }
      
      #progress-node-detail-div, #progress-node-credit-detail-div, #progress-node-credential-detail-div, #min-req-info-div, #min-req-detail-div, .simple-gp-table-div {
        margin-left: 5px; 
        margin-right: 5px; 
        border: none;
        
        > table tr {
          border-bottom: 1px solid #E0E0E0;
          
          > th {
            background: #fff;
            border-bottom: 1px solid #E0E0E0;
          }
        }
      }
      
    }
    
    .progress-node-child-link-cell { 
       padding: 0 0 0 5px;
    }
	
	.progress-node-child-progress-cell { 
      text-align: right; 
      padding: 0 10px 0 0;
      
      > div {
        float: right;
      }
    }    
  }
}

.gp-progress-bc-label {
  padding-left: 0px;
}

.gp-root-progress-info {
  padding-left: 10px;
   > div {
     width: 120px;
   }
}

.gp-progress-multi-print {
  border: thin dotted red; 
  width: 75%;
}

.spstable {
  & tr {
    & th {
      padding-top: 0px;
      padding-bottom: 0px;
    }
    &.advanced-plan th {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  & td, & th {
    padding: 4px 4px 4px 4px;
    &.noplan {
      vertical-align: middle;
      & .feedback-info {
        margin: 0;
      }
    }
  }
}

.gp-progress-legend-icon {
  vertical-align: middle;
}	

pds-icon.at-risk[name=inprogress] svg {
   fill: #FF8C00;
}

pds-icon[name=inprogress] svg {
   fill: #0075DB;
}

.extraInfo {
  font-size: 8px;
}
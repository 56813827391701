.district-push {
   .mainSourceSchoolDiv {
     width: 1000px;
   }
   .targetSchoolsDiv {
     width: 1000px;
   }
   .feedback {
     width: 1040px;
   }
   .fluid-form {
        .schoolYearDiv {
             width: 100px;
             margin-top: 15px;
             margin-left: 50px;
        }
        .termsDefinedDiv {
          width: 200px;
          margin-top: 15px;
          word-break: break-all;
        }
        .editDiv{
          width: 100px;
          margin-top: 35px;
        }
        .autocomplete-combobox-container {
          padding: 0 0 0 0;
        }
        .autocomplete-combobox-container .sourceSchoolSearch {
          margin: 0 0 0 0;
        }
        .autocomplete-combobox-container .sourceDistrictCalendarSearch {
          margin: 0 0 0 0;
        }
        .autocomplete-combobox-container .angucomplete-row {
            min-height : 1em;
            height: auto;
        }
        div:not(.button-row)>div>label {
            padding-left: 0px;
            margin: 2px 2px 2px -2px;
        }
   }
   .step-content .step-content-body {
     max-width: 1150px;
   }
   .sourceSchoolContainer {
     width: 300px;
   }
   .schoolYearSelect {
     width: 100px;
   }
   .ui-dialog-buttonset .confirmDelete .ui-button-text {
     background-color:#FFA500;
   }
   .termLegend{
     color: #666;
   }
   .feebdback-drawer{
      margin : 0px 10px 20px;
   }
   #legend{
     margin: 10px 10px;
   }
   #yearsAndTerms_sourceSchoolSearch_label{
     display : inline-block;
   }
   #psDialog{
     overflow-y : auto;
	 overflow-x : auto;
     max-width : 1250px;
   }
   .psDialog #feedback-message, .psDialog #feedback-message.floaty{
     position : sticky;
   }
}
.schoolDetailTable {
    table-layout: fixed;
}